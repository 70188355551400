import { graphql, StaticQuery } from "gatsby"
import React, { useState } from 'react';
import { useSiteContext } from "../contexts/site"

export default function FAQAccordion() {
  const { locale } = useSiteContext();
  const query = graphql`
    query Accordion {
      firestore {
        en: faqs(locale: "en") {
          id
          question
          answer
        }
        ch: faqs(locale: "ch") {
          id
          question
          answer
        }
      }
    }
  `
  const allowList = [
    'y9aT2tfViGdjNa9tUcRW',
    'TpT3Ebdxuh1Gln579yjx',
    'aVtZU0vV9Kr3El7UXUvE',
    'GXT3xwdgvIcxIDj82wfi',
    'g7StoSigWEl6FTMSOo6y',
    '2aNI7DZ5uBZ3tyKKlOSc',
    '3sKDFYkVhbivmLLu9GwK',
    'MlX0HEOD1Zg9oH3d19tg'
  ]
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className="mx-auto px-8 lg:px-0 max-w-xl">
          <div className="mx-8 lg:mx-auto my-16 text-center prose-sm lg:prose md:max-w-5xl lg:max-w-7xl">
            <h3>{locale === 'ch' ? '常見問題' : 'Frequently Asked Questions'}</h3>
          </div>
          <AccordionItems items={data.firestore[locale].filter(item => allowList.includes(item.id))} />
        </section>
      )}
    />
  )
}

function AccordionItems({ items }) {
  const [revealAnswer, setRevealAnswer] = useState(items.reduce((a, c) => {
    a[c.id] = false;
    return a;
  }, {}))
  return (
    <ul className="space-y-8">
      {
        items.map((item, index) => (
          <li key={item.id} className="flex flex-col bg-gray-100 p-6">
            <div className="flex justify-between text-mango items-center">
              <span>{index+1}. {item.question}</span>
              <button onClick={() => setRevealAnswer({...revealAnswer, [item.id]: !revealAnswer[item.id]})} className="text-2xl">+</button>
            </div>
            { revealAnswer[item.id] && (
              <div className="text-sm leading-7 p-2 prose-sm lg:prose" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
            )}
          </li>
        ))
      }
    </ul>
  )
}
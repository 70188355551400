import React, { useRef } from "react"
import { graphql } from "gatsby"
import Flicking from "@egjs/react-flicking";
import { Fade, AutoPlay } from "@egjs/flicking-plugins";
import AdmissionGreetingCard from "../components/AdmissionGreetingCard"
import SymptomsCard from "../components/SymptomsCard"
import FAQCard from "../components/FAQCard"

import Layout from "../components/Layout";
import LocationCard from "../components/LocationCard";
import VisitFlow from "../components/VisitFlow";
import FAQAccordion from "../components/FAQAccordion";
import TreatmentCarousel from "../components/TreatmentCarousel";

const IndexPage = ({ data, pageContext }) => {

  const translations = {
    en: {
      moreButtonText: 'More'
    },
    ch: {
      moreButtonText: '更多'
    }
  }

  const flicking = useRef(null);
  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: 'https://www.spinecentre.com.hk/_assets/img/logo.svg'
        }
      ]
    }}>
      <Flicking
        className="flicking w-full h-96"
        collectStatistics={false}
        gap={20}
        circular={true}
        duration={300}
        autoResize={true}
        zIndex={20}
        ref={flicking}
        plugins={[new Fade(), new AutoPlay(3000, "NEXT")]}
      >
        {
          data.firestore.page.content[pageContext.locale].slides.map((slide, index) => (
            <div key={slide.title} className="w-full md:h-96 h-44 bg-no-repeat bg-cover" style={{ backgroundImage: `url(${slide.image})`, height: 520 }}>
              <div className="w-full lg:w-1/2 h-full flex flex-col justify-end px-16">
                {
                  index === 0 ? (
                    <h1 className="text-mango md:text-5xl text-2xl mb-4 tracking-wider">{slide.title}</h1>
                  ) : (
                    <h2 className="text-mango md:text-5xl text-2xl mb-4 tracking-wider">{slide.title}</h2>
                  )
                }
                <span className="text-white text-sm md:text-2xl mb-16 leading-8 tracking-wider">{slide.description}</span>
              </div>
            </div>
          ))
        }
      </Flicking>
      <section className="flex flex-col items-center space-y-4 justify-center mt-48 lg:flex-row lg:space-y-0 lg:items-stretch">
        <AdmissionGreetingCard rounded={false} />
        <SymptomsCard rounded={false} />
        <FAQCard rounded={false} />
      </section>
      <section className="mx-8 lg:mx-auto my-16 text-center prose-sm lg:prose md:max-w-5xl lg:max-w-7xl" dangerouslySetInnerHTML={{ __html: data.firestore.page.content[pageContext.locale].intro }} />
      <VisitFlow locale={pageContext.locale} />
      <TreatmentCarousel 
        treatments={data.firestore.treatments}  
        locale={pageContext.locale}
      />
      <FAQAccordion />
      <LocationCard />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery($locale: String!, $pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
      treatments(locale: $locale) {
        id
        title
        href
        description
        thumbnail
      }
    }
  }
`
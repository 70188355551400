import React, { useRef } from "react";
import Flicking from "@egjs/react-flicking";
import { Fade, AutoPlay } from "@egjs/flicking-plugins";

export default function TreatmentCarousel({ treatments, locale }) {
  const flicking = useRef(null);
  const translations = {
    en: {
      title: 'Chiropractic Treatment Methods &ndash; Hong Kong Spine Centre',
      description: `Hong Kong Spine Centre follows the most natural and non-invasive
    chiropractic treatment methods designed to raise the body&rsquo;s
    self-healing ability and restore holistic balance and health. Our team of
    professional chiropractors provide personalised chiropractic adjustment and
    other target treatments to address each patient&rsquo;s individual concerns.`,
      moreButtonText: 'More'
    },
    ch: {
      title: '脊醫治療和療法',
      description: '香港護脊中心會按不同部位的痛症，選擇以下不同脊醫治療療法，以舒緩病人的痛症：',
      moreButtonText: '更多'
    }
  }

  return (
    <section>
      <div className="mx-8 lg:mx-auto my-16 text-center prose-sm lg:prose md:max-w-5xl lg:max-w-7xl">
        <h2 dangerouslySetInnerHTML={{__html: translations[locale].title}}></h2>
        <p dangerouslySetInnerHTML={{__html: translations[locale].description}}></p>
      </div>
      <Flicking
        className="flicking w-full h-[520px]"
        collectStatistics={false}
        gap={20}
        circular={true}
        duration={300}
        ref={flicking}
        autoResize={true}
        zIndex={20}
        plugins={[new Fade(), new AutoPlay(3000, "NEXT")]}>
        {
          treatments.map(item => (
            <div className="md:w-1/3 w-1/2 flex items-center flex-col justify-around h-full" key={item.id}>
              <img className="rounded-full w-48 h-48 lg:w-52 lg:h-52" src={item.thumbnail} alt={item.title} />
              <h3 className="text-mango font-semibold text-xl lg:text-2xl lg:my-2">{item.title}</h3>
              <span className="w-[200px] md:w-[260px] text-base lg:text-xl text-coconut text-center">{item.description}</span>
              <a href={item.href} className="bg-coconut px-4 py-1.5 text-white mt-4 rounded-md">{translations[locale]['moreButtonText']}</a>
            </div>
          ))
        }
      </Flicking>
    </section>
  )
}


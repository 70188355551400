import React from "react";

export default function VisitFlow({ locale }) {
  const translations = {
    en: {
      title: `Chiropractic Consultation Process`,
      description: `When patients visit the Hong Kong Spine Centre for consultation, the centre
    provides personalised treatment and education programs based on the
    patient&#39;s condition, empowering them to lead healthy lives. Here is the
    process for patients undergoing their first visit with our chiropractors at
    the Spine Centre:`
    },
    ch: {
      title: `脊醫診症流程`,
      description: `當病人到香港護脊中心求診，本中心會根據病人的病情，提供個人化的治療及教育方案，讓病人在對症下藥，接受適當的治療後，能夠活出健康的生活。以下是病人在護脊中心中進行首次脊椎治療時的流程：`
    }
  }
  const steps = {
    ch: [
      {
        title: "填寫個人資料",
        description: ""
      },
      {
        title: "病歷詢問",
        description: "脊骨神經科醫生會向病人查詢一些關於病情的細節，例如痛楚何時開始? 曾否有過嚴重意外? 痛楚是否越來越嚴重? 工作時坐姿如何?"
      },
      {
        title: "詳細檢查",
        description: "脊骨神經科醫生會因應患者的病情作出適當檢查，例如活動幅度、神經系統、骨骼及肌腱系統、X光或磁力共震等檢查。"
      },
      {
        title: "診斷",
        description: "脊骨神經科醫生因應病歷和檢查結果等的臨床資料來為您作出診斷。"
      },
      {
        title: "建議治療方法和計劃",
        description: "脊骨神經科醫生會因應您的病情來為您擬定一套治療計劃及合理的收費。治療計劃包括脊椎調整、物理治療、運動治療或姿勢改善矯正和討論生活上可能要注意的細節。"
      }
    ],
    en: [
      {
        title: "Basic personal information",
        description: ""
      },
      {
        title: "Case History",
        description: "Your chiropractor will ask some questions about your complaint, such as “When did the pain start? Was it related to an accident?  Is the pain getting worse? How is your working posture?"
      },
      {
        title: "Physical examination",
        description: "Your chiropractor will conduct appropriate examinations based on your condition.  For example, range of motion tests, orthopedic or neurological examination of the musculoskeletal system, or refer you for imaging studies such as X-rays or MRI"
      },
      {
        title: "Diagnosis",
        description: "Based on the examination results, your chiropractor will explain the findings leading to a diagnosis of your condition"
      },
      {
        title: "Suggested treatment plan",
        description: "Your chiropractor will propose a suitable treatment plan for you. Treatments may include spinal manipulation, electrical modalities, exercise, and lifestyle adjustments."
      }
    ]
  }
  return (
    <section className="my-16 mx-auto px-8 lg:px-0 max-w-xl">
      <div className="mx-8 lg:mx-auto my-16 text-center prose-sm lg:prose md:max-w-5xl lg:max-w-7xl">
        <h2>{translations[locale].title}</h2>
        <p dangerouslySetInnerHTML={{__html: translations[locale].description}}></p>
      </div>
      <ul className="space-y-16">
        {
          steps[locale].map((step, idx) => (
            <li key={idx} className={`flex h-52 lg:h-36 flex-col ${idx%2==0?'lg:flex-row':'lg:flex-row-reverse'}`}>
              <div className={`bg-mango flex items-center justify-center py-4 lg:py-0 w-full lg:w-40 text-white text-2xl lg:text-4xl rounded-t-md ${idx%2==0?'lg:rounded-l-md lg:rounded-r-none':'lg:rounded-r-md lg:rounded-l-none'}`}>
                {idx+1}
              </div>
              <dl className={`flex flex-col justify-center items-center lg:items-start bg-gray-100 space-y-4 px-8 py-4 lg:py-0 flex-1 rounded-b-md ${idx%2==0?'lg:rounded-r-md':'lg:rounded-l-md'}`}>
                <dt className="text-mango">{step.title}</dt>
                <dd className="text-sm">{step.description}</dd>
              </dl>
            </li>
          ))
        }
      </ul>
    </section>
  )
}